import { createContext, useContext, useEffect, useState } from "react";
//import { usePublicClient } from "wagmi";
import { CANARY_DAO_ABI, DAO_CONTRACT_ADDRESS, frbfarm_ABI, FRBFARM_CONTRACT_ADDRESS } from "../lib/constants";
import { formatUnits } from "viem";
import { useAccount, usePublicClient } from "wagmi";

const defaultValues = {
  rebaseInterval: 0,
  lastRebaseTime: 0,
  rebaseRate: 0,
  getFrbRebaseInfo: () => {},
};

const FrbRebaseContext = createContext(defaultValues);

export const FrbRebaseProvider = ({ children }) => {
  const publicClient = usePublicClient();
  const { address, isConnected } = useAccount();
  const [state, setState] = useState({
    rebaseInterval: 0,
    lastRebaseTime: 0,
    rebaseRate: 0,
  });

  const getFrbRebaseInfo = async (address) => {
   
      const [rebaseInterval, lastRebaseTime, rebaseRate] = await Promise.all([
        publicClient.readContract({
          abi: frbfarm_ABI,
          address: FRBFARM_CONTRACT_ADDRESS,
          functionName: "harvestFrequency",
          args: [],
        }),
        publicClient.readContract({
          abi: frbfarm_ABI,
          address: FRBFARM_CONTRACT_ADDRESS,
          functionName: "getLastHarvest",
          args: [address],
        }),
        publicClient.readContract({
          abi: frbfarm_ABI,
          address: FRBFARM_CONTRACT_ADDRESS,
          functionName: "harvestAmount",
          args: [],
        }),
      ]);
      
      setState({
        rebaseInterval: formatUnits(rebaseInterval, 0),
        lastRebaseTime: formatUnits(lastRebaseTime, 0),
        rebaseRate: formatUnits(rebaseRate, 0),
      });
  };
  useEffect(() => {
    isConnected && getFrbRebaseInfo(address);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [address, isConnected]);

  return (
    <FrbRebaseContext.Provider
      value={{
        ...state,
        getFrbRebaseInfo,
      }}
    >
      {children}
    </FrbRebaseContext.Provider>
  );
};

export const useFrbRebase = () => useContext(FrbRebaseContext);
