import { Button, Card } from "@/src/components";
import { useStake } from "@/src/hooks/useStake";
import { ReloadIcon } from "@/src/icons";
import { useFrbRebase, useRebase } from "@/src/context";
import { toast } from "react-hot-toast";
import { useAccount } from "wagmi";
import { useState, useEffect, useRef } from "react";
import { useFrbBalances } from "@/src/hooks/useBalances";
import { activeChain } from "@/src/configs/chainConfig";

export const FrbHarvestButton = () => {
  const { chain } = useAccount();
  const [rebaseInterval, setRebaseInterval] = useState(0);
  const [lastRebaseTime, setLastRebaseTime] = useState(0);
  const [rebaseRate, setRebaseRate] = useState(0);
  const {stakeBalance, maxStakingAmount} = useFrbBalances();
  const [timeCheck, setTime] = useState(0);
  const{timeCheck: timeCheckProp} = useState(new Date());
  const { rebaseInterval: rebaseIntervalProp, lastRebaseTime: lastRebaseTimeProp, rebaseRate: rebaseRateProp} = useFrbRebase();
  useEffect(() => {
    setRebaseInterval(parseInt(rebaseIntervalProp));
    setLastRebaseTime(parseInt(lastRebaseTimeProp));
    setRebaseRate(parseFloat(rebaseRateProp));
    setTime(timeCheckProp);
  }, [rebaseIntervalProp, lastRebaseTimeProp, rebaseRateProp, timeCheckProp]);
  const { isConnected } = useAccount();
  const { onLpHarvest } = useStake();
  const [isValid, setValid] = useState(false);

  const [{ isCorrectChain }, setState] = useState({
    isCorrectChain: true,
  });

  useEffect(() => {
    if (isConnected)
      setState({
        isCorrectChain: chain?.id === Number(activeChain.chainId),
      });
    
  }, [chain, isConnected]);

  const validate = () => {
    if (stakeBalance == 0){
      return false;
    }
    if (parseFloat(stakeBalance) > parseFloat(maxStakingAmount)){
      return false;
    }
    if (!isCorrectChain){
      return false;
    }
    if(lastRebaseTime > 0){
      return Date.now()/1000 > (lastRebaseTime + rebaseInterval);

    }
    else{return true;}
  };
  useEffect(() => {
    const isValid = validate();
    setValid(isValid);
  }, [lastRebaseTime, rebaseInterval, timeCheck]);

  useEffect(() => {
    const interval = setInterval(() => {
      setTime(new Date());
    }, 2000);
    return () => clearInterval(interval);
  }, []);

  const handleRebase = async () => {
    if (!isConnected) return;

    await toast.promise(onLpHarvest(), {
      loading: "Transaction is in progress",
      success: "Successfully harvested FRB",
      error: (data) => data,
    }
    );
    await new Promise(resolve => setTimeout(resolve, 2000));
    window.location.reload(false);
  };
  return (
    <Card className="h-1/5">
      <div className="flex items-center h-full">
        <Button onClick={handleRebase} className="" disabled={!isValid}>
          <ReloadIcon className="h-7 w-8 text-foreground" />
          <h2 className="md:text-2xl text-xl text-foreground">Harvest</h2>
        </Button>
        {}
      </div>
    </Card>
  );
};
