import { BankIcon, Query } from "@/src/icons";
import { Card, Button } from "@/src/components";
import { useBalances, useFrbBalances } from "@/src/hooks/useBalances";
import { useStake } from "@/src/hooks/useStake";
import { useAccount } from "wagmi";
import { toast } from "react-hot-toast";

export const FrbNftStaking = () => {
  const { maxStakingAmount, bronzeStaked, silverStaked, goldStaked } = useFrbBalances();
  const { bronzeAmount, silverAmount, goldAmount } = useBalances();
  const { onStakeNfts, onUnstakeNfts } = useStake();
  const { isConnected } = useAccount();

  const handleStakeNfts = async () => {
    if (!isConnected) return;

    toast.promise(onStakeNfts(), {
      loading: "Transaction is in progress",
      success: "Successfully staked NFTs",
      error: (data) => data,
    });
  }
  const handleUnstakeNfts = async () => {
    if (!isConnected) return;

    toast.promise(onUnstakeNfts(), {
      loading: "Transaction is in progress",
      success: "Successfully unstaked NFTs",
      error: (data) => data,
    });
  }
  return (
    <Card className="md:h-2/7 py-5">
      <div className="grid gap-3">
        <div>
          <p className="text-2xl md:text-3xl font-semibold text-foreground">
          {parseFloat(maxStakingAmount).toFixed(2)} LP Tokens
          </p>
          <h2 className="text-xl font-semibold text-foreground/50">Maximum Stake Amount</h2>
        </div>
        <div className="flex items-center h-full">
        <Button onClick={handleStakeNfts} className="" disabled={parseFloat(bronzeAmount)==0 && parseFloat(silverAmount)==0 && parseFloat(goldAmount)==0 }>
          <h2 className="md:text-xl text-xl text-foreground">Stake Coin NFTs</h2>
        </Button>
        {}
      </div>
      <div className="flex items-center h-full">
        <Button onClick={handleUnstakeNfts} className="" disabled={parseFloat(bronzeStaked)==0 && parseFloat(silverStaked)==0 && parseFloat(goldStaked)==0 }>
          <h2 className="md:text-xl text-xl text-foreground">Unstake Coin NFTs</h2>
        </Button>
        {}
      </div>
      </div>
    </Card>
    
  );
};
