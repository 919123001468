import { BankIcon, Query } from "@/src/icons";
import { Card, Button } from "@/src/components";
import { useFrbBalances } from "@/src/hooks/useBalances";
import { useStake } from "@/src/hooks/useStake";
import { useAccount } from "wagmi";
import { toast } from "react-hot-toast";

export const FrbPendingSnapshot = () => {
  const { pendingSnapshotAmount } = useFrbBalances();
  const { onClaimAsAirdrop } = useStake();
  const { isConnected } = useAccount();

  return (
    <Card className="md:h-2/7 py-5">
      <div className="grid gap-3">
        <div>
          <p className="text-2xl md:text-3xl font-semibold text-foreground">
          {parseFloat(pendingSnapshotAmount).toFixed(2)} FRB
          </p>
          <h2 className="text-xl font-semibold text-foreground/50">Pending FRB Snapshot</h2>
        </div>
      </div>
    </Card>
    
  );
};
