import { FileAdd } from "@/src/icons";

export const navLinks = [
  {
    icon: "",
    label: "Stake CRB",
    href: "/",
  },
  {
    icon: "",
    label: "CRB Auto Harvest",
    href: "/chad",
  },
  {
    icon: "",
    label: "Farm FRB",
    href: "FrbStake",
    target: "/frbStake",
  },
  {
    icon: "",
    label: "Rewards",
    href: "/rewards",
  },
  {
    icon: "",
    label: "Docs",
    href: "https://docs.canary-reborn.xyz",
    target: "_blank",
  },
  {
    icon: "",
    label: "Metrics",
    href: "/metrics",
  },
];
