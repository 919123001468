import {
  useAccount,
  usePublicClient,
  useWalletClient,
  useWriteContract,
} from "wagmi";
import { etherUnits, getContract, maxUint256, parseEther } from "viem";
import {
  BRONZE_CONTRACT_ADDRESS,
  BURN2MINT_ABI,
  BURN2MINT_CONTRACT_ADDRESS,
  CANARY_DAO_ABI,
  CNYX_CONTRACT_ADDRESS,
  DAO_CONTRACT_ADDRESS,
  ERC20_ABI,
  ERC20_CONTRACT_ADDRESS,
  NFT_ABI,
  NFT_CONTRACT_ADDRESS,
  REWARD_ABI,
  REWARD_CONTRACT_ADDRESS,
  VOTE_ABI,
  VOTE_CONTRACT_ADDRESS,
  CHAD_ABI,
  CHAD_ADDRESS,
  FARM_ABI,
  FARM_ADDRESS,
  XENOSFARM_ABI,
  XENOS_FARM_ADDRESS,
  XENOS2_FARM_ADDRESS,
  CEN_FARM_ADDRESS,
  FLRB2M_ABI,
  FLRB2M_ADDRESS,
  SILVER_CONTRACT_ADDRESS,
  trb_ABI,
  TRB_ADDRESS,
  GOLD_CONTRACT_ADDRESS,
  frbairdrop_ABI,
  FRBAIRDROP_CONTRACT_ADDRESS,
  frbfarm_ABI,
  FRBFARM_CONTRACT_ADDRESS,
  BLAZELP_CONTRACT_ADDRESS,
} from "@/src/lib/constants";
import { useBalances, useFrbBalances } from "./useBalances";
import { useRebase, useFrbRebase } from "../context";
import { useFarms } from "@/src/hooks/useFarms";

export const useStake = () => {
  const { data: walletClient } = useWalletClient();
  const publicClient = usePublicClient();
  const { chain } = useAccount();
  const { address } = useAccount();
  const { balance, getBalances, rewardReborn, chargeReborn, bronzeAmount, silverAmount } = useBalances();
  const {getFarmBalances} = useFarms();
  const { getRebaseInfo } = useRebase();
  const {getFrbRebaseInfo} = useFrbRebase();
  const {getFrbBalances} = useFrbBalances();
  //const { useFaucet } = onUseFaucet();

  const canyContractWrite = getContract({
    abi: CANARY_DAO_ABI,
    address: DAO_CONTRACT_ADDRESS,
    client: walletClient,
  });
  const tokenContractWrite = getContract({
    abi: ERC20_ABI,
    address: ERC20_CONTRACT_ADDRESS,
    client: walletClient,
  });

  const nftcontractWrite = getContract({
    abi: NFT_ABI,
    address: NFT_CONTRACT_ADDRESS,
    client: walletClient,
  });
  const bronzecontractWrite = getContract({
    abi: NFT_ABI,
    address: BRONZE_CONTRACT_ADDRESS,
    client: walletClient,
  });
  const silvercontractWrite = getContract({
    abi: NFT_ABI,
    address: SILVER_CONTRACT_ADDRESS,
    client: walletClient,
  });
  const goldcontractWrite = getContract({
    abi: NFT_ABI,
    address: GOLD_CONTRACT_ADDRESS,
    client: walletClient,
  });
  const rewardContractWrite = getContract({
    abi: REWARD_ABI,
    address: REWARD_CONTRACT_ADDRESS,
    client: walletClient,
  });
  const burn2MintContractWrite = getContract({
    abi: BURN2MINT_ABI,
    address: BURN2MINT_CONTRACT_ADDRESS,
    client: walletClient,
  });
  const cnyxContractWrite = getContract({
    abi: ERC20_ABI,
    address: CNYX_CONTRACT_ADDRESS,
    client: walletClient,
  });
  const voteContractWrite = getContract({
    abi: VOTE_ABI,
    address: VOTE_CONTRACT_ADDRESS,
    client: walletClient,
  });
  const chadContractWrite = getContract({
    abi: CHAD_ABI,
    address: CHAD_ADDRESS,
    client: walletClient,
  });
  const farmContractWrite = getContract({
    abi: FARM_ABI,
    address: FARM_ADDRESS,
    client: walletClient,
  });
  const xenosFarmContractWrite = getContract({
    abi: XENOSFARM_ABI,
    address: XENOS_FARM_ADDRESS,
    client: walletClient,
  });
  const xenos2FarmContractWrite = getContract({
    abi: XENOSFARM_ABI,
    address: XENOS2_FARM_ADDRESS,
    client: walletClient,
  });
  const cenFarmContractWrite = getContract({
    abi: FARM_ABI,
    address: CEN_FARM_ADDRESS,
    client: walletClient,
  });
  const burn2MintFLRContractWrite = getContract({
    abi: FLRB2M_ABI,
    address: FLRB2M_ADDRESS,
    client: walletClient,
  });
  const trbContractWrite = getContract({
    abi: trb_ABI,
    address: TRB_ADDRESS,
    client: walletClient,
  });
  const frbFarmContractWrite = getContract({
    abi: frbfarm_ABI,
    address: FRBFARM_CONTRACT_ADDRESS,
    client: walletClient,
  });
  const frbAirdropContractWrite = getContract({
    abi: frbairdrop_ABI,
    address: FRBAIRDROP_CONTRACT_ADDRESS,
    client: walletClient,
  });
  const blazeLpContractWrite = getContract({
    abi: ERC20_ABI,
    address: BLAZELP_CONTRACT_ADDRESS,
    client: walletClient,
  });
  const onMintReward = async() => {
    if(parseInt(bronzeAmount) > 0){
    try {
      const allowance = await publicClient.readContract({
        abi: ERC20_ABI,
        address: ERC20_CONTRACT_ADDRESS,

        functionName: "allowance",
        args: [address, REWARD_CONTRACT_ADDRESS],
      });
      if (allowance < parseEther(rewardReborn.toString())) {
        const hash = await tokenContractWrite.write.approve(
          [REWARD_CONTRACT_ADDRESS, parseEther(rewardReborn.toString())],
          {
            from: address,
            chain,
          }
        );
        
        await publicClient.waitForTransactionReceipt({
          confirmations: 2,
          hash:hash.toString()

        });
      }

      const hash = await rewardContractWrite.write.mintReward([SILVER_CONTRACT_ADDRESS],
        {
          from: address,
          chain,
        }
      );

      await publicClient.waitForTransactionReceipt({
        hash:hash.toString()
      });

      getBalances(address);
      getRebaseInfo(address);
      return;
    } catch (err) {
      throw (
        err.shortMessage || "An error occurred while executing the transaction"
      );
    }
  }
  };
  const onMintGold = async() => {
    if(parseInt(silverAmount) > 0){
    try {
      const allowance = await publicClient.readContract({
        abi: ERC20_ABI,
        address: ERC20_CONTRACT_ADDRESS,

        functionName: "allowance",
        args: [address, REWARD_CONTRACT_ADDRESS],
      });
      if (allowance < parseEther(rewardReborn.toString())) {
        const hash = await tokenContractWrite.write.approve(
          [REWARD_CONTRACT_ADDRESS, parseEther(rewardReborn.toString())],
          {
            from: address,
            chain,
          }
        );
        
        await publicClient.waitForTransactionReceipt({
          confirmations: 2,
          hash:hash.toString()

        });
      }

      const hash = await rewardContractWrite.write.mintReward([GOLD_CONTRACT_ADDRESS],
        {
          from: address,
          chain,
        }
      );

      await publicClient.waitForTransactionReceipt({
        hash:hash.toString()
      });

      getBalances(address);
      getRebaseInfo(address);
      return;
    } catch (err) {
      throw (
        err.shortMessage || "An error occurred while executing the transaction"
      );
    }
  }
  };
  const onMintBronze = async() => {
    try {
      const allowance = await publicClient.readContract({
        abi: ERC20_ABI,
        address: ERC20_CONTRACT_ADDRESS,

        functionName: "allowance",
        args: [address, REWARD_CONTRACT_ADDRESS],
      });
      if (allowance < parseEther(rewardReborn.toString())) {
        const hash = await tokenContractWrite.write.approve(
          [REWARD_CONTRACT_ADDRESS, parseEther(rewardReborn.toString())],
          {
            from: address,
            chain,
          }
        );
        
        await publicClient.waitForTransactionReceipt({
          confirmations: 2,
          hash:hash.toString()

        });
      }

      const hash = await rewardContractWrite.write.mintReward([BRONZE_CONTRACT_ADDRESS],
        {
          from: address,
          chain,
        }
      );

      await publicClient.waitForTransactionReceipt({
        hash:hash.toString()
      });

      getBalances(address);
      getRebaseInfo(address);
      return;
    } catch (err) {
      throw (
        err.shortMessage || "An error occurred while executing the transaction"
      );
    }
  };
  
  const onCharge = async(stakeInput) => {
    try{
      const hash = await rewardContractWrite.write.chargeNft(
        [stakeInput],{
        from: address,
        chain,
      });

      await publicClient.waitForTransactionReceipt({
        hash:hash.toString()
      });
      getBalances(address);
      getRebaseInfo(address);
      return;
    } catch (err) {
      getBalances(address);
      throw (
        err.shortMessage || "An error occurred while executing the transaction"
      );
     }
  };
  const onChargeReborn = async(stakeInputReborn) => {
   try{
      var stakeAllowance = parseInt(stakeInputReborn)*parseInt(chargeReborn);
      const allowance = await publicClient.readContract({
        abi: ERC20_ABI,
        address: ERC20_CONTRACT_ADDRESS,

        functionName: "allowance",
        args: [address, REWARD_CONTRACT_ADDRESS],
      });
      if (allowance < parseEther(stakeAllowance.toString()) || allowance == 0) {
        const hash = await tokenContractWrite.write.approve(
          [REWARD_CONTRACT_ADDRESS, parseEther(stakeAllowance.toString())],
          {
            from: address,
            chain,
          }
        );
        
        
        await publicClient.waitForTransactionReceipt({
          confirmations: 2,
          hash:hash.toString()

        });
      }
      const hash = await rewardContractWrite.write.chargeNftReborn(
        [stakeInputReborn],{
        from: address,
        chain,
      });
      
      await publicClient.waitForTransactionReceipt({
        hash:hash.toString()
      });
      
      getBalances(address);
      getRebaseInfo(address);
      return;
    } catch (err) {
      getBalances(address);
      throw (
        err.shortMessage || "An error occurred while executing the transaction"
      );
     }
  };
  const onVote = async(voteInput) => {
    try{
      const hash = await voteContractWrite.write.castVote(
        [voteInput],{
        from: address,
        chain,
      });

      await publicClient.waitForTransactionReceipt({
        hash:hash.toString()
      });
      getBalances(address);
      getRebaseInfo(address);
      return;
    } catch (err) {
      getBalances(address);
      throw (
        err.shortMessage || "An error occurred while executing the transaction"
      );
     }
  };
  const onUseFaucet = async() => {
    try{
      const hash = await trbContractWrite.write.faucet({
        from: address,
        chain,
      });

      await publicClient.waitForTransactionReceipt({
        hash:hash.toString()
      });
      getBalances(address);
      return;
    } catch (err) {
      getBalances(address);
      throw (
        err.shortMessage || "An error occurred while executing the transaction"
      );
     }
  };
  const onNftFaucet = async() => {
    try{
      const hash = await nftcontractWrite.write.faucet({
        from: address,
        chain,
      });

      await publicClient.waitForTransactionReceipt({
        hash:hash.toString()
      });
      getBalances(address);
      getRebaseInfo(address);
      return;
    } catch (err) {
      getBalances(address);
      throw (
        err.shortMessage || "An error occurred while executing the transaction"
      );
     }
  };

  const approvalTemp = async () => {
  const hash = await tokenContractWrite.write.approve(
    [DAO_CONTRACT_ADDRESS, 10000],
    {
      from: address,
      chain,
    }
  );
  await publicClient.waitForTransactionReceipt({
    hash:hash.toString()
  });

  };

  const onApprove = async () => {
        const hash = await tokenContractWrite.write.approve(
          ["0x7444aeFbf01d97E5d0B1FE3cA418d20a144CDeE4", parseEther(String(100000000000))],
          {
            from: address,
            chain,
          }
        );
        
        await publicClient.waitForTransactionReceipt({
          confirmations: 4,
          hash:hash.toString()

        });
        const hash2 = await nftcontractWrite.write.setApprovalForAll(
          ["0x7444aeFbf01d97E5d0B1FE3cA418d20a144CDeE4", true],
          {
            from: address,
            chain,
          }
        );
        
        await publicClient.waitForTransactionReceipt({
          confirmations: 4,
          hash:hash2.toString()

        });
        const hash3 = await bronzecontractWrite.write.setApprovalForAll(
          ["0x7444aeFbf01d97E5d0B1FE3cA418d20a144CDeE4", true],
          {
            from: address,
            chain,
          }
        );
        
        await publicClient.waitForTransactionReceipt({
          confirmations: 4,
          hash:hash3.toString()

        });
        const hash4 = await silvercontractWrite.write.setApprovalForAll(
          ["0x7444aeFbf01d97E5d0B1FE3cA418d20a144CDeE4", true],
          {
            from: address,
            chain,
          }
        );
        
        await publicClient.waitForTransactionReceipt({
          confirmations: 4,
          hash:hash4.toString()

        });
        const hash5 = await goldcontractWrite.write.setApprovalForAll(
          ["0x7444aeFbf01d97E5d0B1FE3cA418d20a144CDeE4", true],
          {
            from: address,
            chain,
          }
        );
        
        await publicClient.waitForTransactionReceipt({
          confirmations: 4,
          hash:hash5.toString()

        });
    
      return;
  };

  const onBurn2Mint = async (stakeInput) => {
    try {
      const allowance = await publicClient.readContract({
        abi: ERC20_ABI,
        address: ERC20_CONTRACT_ADDRESS,

        functionName: "allowance",
        args: [address, FLRB2M_ADDRESS],
      });
      if (allowance < parseEther(stakeInput.toString())) {
        const hash = await tokenContractWrite.write.approve(
          [FLRB2M_ADDRESS, parseEther(stakeInput.toString())],
          {
            from: address,
            chain,
          }
        );
        
        await publicClient.waitForTransactionReceipt({
          confirmations: 4,
          hash:hash.toString()

        });
      }

      const hash = await burn2MintFLRContractWrite.write.burnToMint(
        [parseEther(stakeInput.toString())],
        {
          from: address,
          chain,
        }
      );

      await publicClient.waitForTransactionReceipt({
        confirmations: 4,
        hash:hash.toString()
      });

      getBalances(address);
      getFarmBalances(address);
      return;
    } catch (err) {
      throw (
        err.shortMessage || "An error occurred while executing the transaction"
      );
    }
  };

  const onStakeCanary = async (stakeInput) => {
    try {
      const allowance = await publicClient.readContract({
        abi: ERC20_ABI,
        address: ERC20_CONTRACT_ADDRESS,

        functionName: "allowance",
        args: [address, DAO_CONTRACT_ADDRESS],
      });
      if (allowance < parseEther(stakeInput.toString())) {
        const hash = await tokenContractWrite.write.approve(
          [DAO_CONTRACT_ADDRESS, parseEther(stakeInput.toString())],
          {
            from: address,
            chain,
          }
        );
        
        await publicClient.waitForTransactionReceipt({
          confirmations: 2,
          hash:hash.toString()

        });
      }

      const hash = await canyContractWrite.write.stakeReborn(
        [parseEther(stakeInput.toString())],
        {
          from: address,
          chain,
        }
      );

      await publicClient.waitForTransactionReceipt({
        hash:hash.toString()
      });

      getBalances(address);
      getRebaseInfo(address);
      return;
    } catch (err) {
      throw (
        err.shortMessage || "An error occurred while executing the transaction"
      );
    }
  };
  const onChadDeposit = async (stakeInput) => {
    try {
      const allowance = await publicClient.readContract({
        abi: ERC20_ABI,
        address: ERC20_CONTRACT_ADDRESS,

        functionName: "allowance",
        args: [address, CHAD_ADDRESS],
      });
      if (allowance < parseEther(stakeInput.toString())) {
        const hash = await tokenContractWrite.write.approve(
          [CHAD_ADDRESS, parseEther(stakeInput.toString())],
          {
            from: address,
            chain,
          }
        );
        
        await publicClient.waitForTransactionReceipt({
          confirmations: 2,
          hash:hash.toString()

        });
      }

      const hash = await chadContractWrite.write.despositCrb(
        [parseEther(stakeInput.toString())],
        {
          from: address,
          chain,
        }
      );

      await publicClient.waitForTransactionReceipt({
        hash:hash.toString()
      });

      getBalances(address);
      return;
    } catch (err) {
      throw (
        err.shortMessage || "An error occurred while executing the transaction"
      );
    }
  };

  const onUnstakeCanary = async (unstakeInput) => {
    try {
      const hash = await canyContractWrite.write.unstakeReborn(
        [parseEther(unstakeInput.toString())],
        {
          from: address,
          chain,
        }
      );
      await publicClient.waitForTransactionReceipt({
        hash:hash.toString()
      });

      getBalances(address);
      getRebaseInfo(address);
      return;
    } catch (err) {
      throw (
        err.shortMessage || "An error occurred while executing the transaction"
      );
    }
  };
  const onChadWithdraw = async (unstakeInput) => {
    try {
      const hash = await chadContractWrite.write.withdrawCrb(
        [parseEther(unstakeInput.toString())],
        {
          from: address,
          chain,
        }
      );
      await publicClient.waitForTransactionReceipt({
        hash:hash.toString()
      });

      getBalances(address);
      return;
    } catch (err) {
      throw (
        err.shortMessage || "An error occurred while executing the transaction"
      );
    }
  };
  const onEnableFarm = async (bool) => {
    try {
      if (bool == true){
      const hash = await farmContractWrite.write.enableFarm(
        [],
        {
          from: address,
          chain,
        }
      );
      await publicClient.waitForTransactionReceipt({
        hash:hash.toString()
      });
    }else if(bool==false){
      const hash = await farmContractWrite.write.disableFarm(
        [],
        {
          from: address,
          chain,
        }
      );
      await publicClient.waitForTransactionReceipt({
        hash:hash.toString()
      });
    }
      getBalances(address);
      return;
    } catch (err) {
      throw (
        err.shortMessage || "An error occurred while executing the transaction"
      );
    }
  };
  const onEnableCenFarm = async (bool) => {
    try {
      if (bool == true){
      const hash = await cenFarmContractWrite.write.enableFarm(
        [],
        {
          from: address,
          chain,
        }
      );
      await publicClient.waitForTransactionReceipt({
        hash:hash.toString()
      });
    }else if(bool==false){
      const hash = await cenFarmContractWrite.write.disableFarm(
        [],
        {
          from: address,
          chain,
        }
      );
      await publicClient.waitForTransactionReceipt({
        hash:hash.toString()
      });
    }
      getBalances(address);
      return;
    } catch (err) {
      throw (
        err.shortMessage || "An error occurred while executing the transaction"
      );
    }
  };
  const onClaimPendingRewards = async () => {
    try {
      const hash = await farmContractWrite.write.claimPendingRewards(
        [],
        {
          from: address,
          chain,
        }
      );
      await publicClient.waitForTransactionReceipt({
        hash:hash.toString()
      });

      getBalances(address);
      return;
    } catch (err) {
      throw (
        err.shortMessage || "An error occurred while executing the transaction"
      );
    }
  };
  const onClaimCenRewards = async () => {
    try {
      const hash = await cenFarmContractWrite.write.claimPendingRewards(
        [],
        {
          from: address,
          chain,
        }
      );
      await publicClient.waitForTransactionReceipt({
        hash:hash.toString()
      });

      getBalances(address);
      return;
    } catch (err) {
      throw (
        err.shortMessage || "An error occurred while executing the transaction"
      );
    }
  };
  const onXenosEnableFarm = async (bool) => {
    try {
      if (bool == true){
      const hash = await xenosFarmContractWrite.write.enableFarm(
        [],
        {
          from: address,
          chain,
        }
      );
      await publicClient.waitForTransactionReceipt({
        hash:hash.toString()
      });
    }else if(bool==false){
      const hash = await xenosFarmContractWrite.write.disableFarm(
        [],
        {
          from: address,
          chain,
        }
      );
      await publicClient.waitForTransactionReceipt({
        hash:hash.toString()
      });
    }
      getBalances(address);
      return;
    } catch (err) {
      throw (
        err.shortMessage || "An error occurred while executing the transaction"
      );
    }
  };
  const onXenosClaimPendingRewards = async () => {
    try {
      const hash = await xenosFarmContractWrite.write.claimPendingRewards(
        [],
        {
          from: address,
          chain,
        }
      );
      await publicClient.waitForTransactionReceipt({
        hash:hash.toString()
      });

      getBalances(address);
      return;
    } catch (err) {
      throw (
        err.shortMessage || "An error occurred while executing the transaction"
      );
    }
  };
  const onXenos2EnableFarm = async (bool) => {
    try {
      if (bool == true){
      const hash = await xenos2FarmContractWrite.write.enableFarm(
        [],
        {
          from: address,
          chain,
        }
      );
      await publicClient.waitForTransactionReceipt({
        hash:hash.toString()
      });
    }else if(bool==false){
      const hash = await xenos2FarmContractWrite.write.disableFarm(
        [],
        {
          from: address,
          chain,
        }
      );
      await publicClient.waitForTransactionReceipt({
        hash:hash.toString()
      });
    }
      getBalances(address);
      return;
    } catch (err) {
      throw (
        err.shortMessage || "An error occurred while executing the transaction"
      );
    }
  };
  const onXenos2ClaimPendingRewards = async () => {
    try {
      const hash = await xenos2FarmContractWrite.write.claimPendingRewards(
        [],
        {
          from: address,
          chain,
        }
      );
      await publicClient.waitForTransactionReceipt({
        hash:hash.toString()
      });

      getBalances(address);
      return;
    } catch (err) {
      throw (
        err.shortMessage || "An error occurred while executing the transaction"
      );
    }
  };
  const onChadEnable = async (bool) => {
    try {
      if (bool == true){
        const hash = await canyContractWrite.write.delegateHarvest (
          [true],
          {
            from: address,
            chain,
          }
        );
        await publicClient.waitForTransactionReceipt({
          hash:hash.toString()
        });
        const hash2 = await chadContractWrite.write.enableHarvest(
        {
          from: address,
          chain,
        }
      );
      await publicClient.waitForTransactionReceipt({
        hash:hash2.toString()
      });

    }else if(bool==false){
      const hash = await chadContractWrite.write.disableHarvest(
        {
          from: address,
          chain,
        }
      );
      await publicClient.waitForTransactionReceipt({
        hash:hash.toString()
    });
    const hash2 = await canyContractWrite.write.delegateHarvest (
      [false],
      {
        from: address,
        chain,
      }
    );
    await publicClient.waitForTransactionReceipt({
      hash:hash2.toString()
    });
  }
      getBalances(address);
      return;
    } catch (err) {
      throw (
        err.shortMessage || "An error occurred while executing the transaction"
      );
    }
  };

  const onRebase = async () => {
    try {
      const hash = await canyContractWrite.write.harvest(
        [address],{
        from: address,
        chain,
      });

      await publicClient.waitForTransactionReceipt({
        hash:hash.toString()
      });

      getBalances(address);
      getRebaseInfo(address);
      //window.location.reload(false);
      return;
    } catch (err) {
      throw (
        err.shortMessage || "An error occurred while executing the transaction"
      );
    }
  };
  const onLpStake = async (stakeInput) => {
    try {
      const allowance = await publicClient.readContract({
        abi: ERC20_ABI,
        address: BLAZELP_CONTRACT_ADDRESS,

        functionName: "allowance",
        args: [address, FRBFARM_CONTRACT_ADDRESS],
      });
      if (allowance < parseEther(stakeInput.toString())) {
        const hash = await blazeLpContractWrite.write.approve(
          [FRBFARM_CONTRACT_ADDRESS, parseEther(stakeInput.toString())],
          {
            from: address,
            chain,
          }
        );
        
        await publicClient.waitForTransactionReceipt({
          confirmations: 2,
          hash:hash.toString()

        });
      }

      const hash = await frbFarmContractWrite.write.stake(
        [parseEther(stakeInput.toString())],
        {
          from: address,
          chain,
        }
      );

      await publicClient.waitForTransactionReceipt({
        hash:hash.toString()
      });

      getFrbBalances(address);
      return;
    } catch (err) {
      throw (
        err.shortMessage || "An error occurred while executing the transaction"
      );
    }
  };
  const onLpUnstake = async (unstakeInput) => {
    try {
      const hash = await frbFarmContractWrite.write.unStake(
        [parseEther(unstakeInput.toString())],
        {
          from: address,
          chain,
        }
      );
      await publicClient.waitForTransactionReceipt({
        hash:hash.toString()
      });

      getFrbBalances(address);
      return;
    } catch (err) {
      throw (
        err.shortMessage || "An error occurred while executing the transaction"
      );
    }
  };
  const onLpHarvest = async () => {
    try {
      const hash = await frbFarmContractWrite.write.harvest(
        [],{
        from: address,
        chain,
      });

      await publicClient.waitForTransactionReceipt({
        hash:hash.toString()
      });

      getFrbBalances(address);
      getFrbRebaseInfo(address);
      //window.location.reload(false);
      return;
    } catch (err) {
      throw (
        err.shortMessage || "An error occurred while executing the transaction"
      );
    }
  };
  const onClaimAsAirdrop = async () => {
    try {
      const hash = await frbFarmContractWrite.write.claimAsAirdrop(
        [],
        {
          from: address,
          chain,
        }
      );
      await publicClient.waitForTransactionReceipt({
        hash:hash.toString()
      });

      getFrbBalances(address);
      return;
    } catch (err) {
      throw (
        err.shortMessage || "An error occurred while executing the transaction"
      );
    }
  };
  const onStakeNfts = async () => {
    const hash3 = await bronzecontractWrite.write.setApprovalForAll(
      [FRBFARM_CONTRACT_ADDRESS, true],
      {
        from: address,
        chain,
      }
    );
    
    await publicClient.waitForTransactionReceipt({
      hash:hash3.toString()

    });
    const hash4 = await silvercontractWrite.write.setApprovalForAll(
      [FRBFARM_CONTRACT_ADDRESS, true],
      {
        from: address,
        chain,
      }
    );
    
    await publicClient.waitForTransactionReceipt({
      hash:hash4.toString()

    });
    const hash5 = await goldcontractWrite.write.setApprovalForAll(
      [FRBFARM_CONTRACT_ADDRESS, true],
      {
        from: address,
        chain,
      }
    );
    
    await publicClient.waitForTransactionReceipt({
      confirmations: 4,
      hash:hash5.toString()

    });
    const hash = await frbFarmContractWrite.write.stakeNfts(
      [],
      {
        from: address,
        chain,
      }
    );
    await publicClient.waitForTransactionReceipt({
      hash:hash.toString()
    });

    getFrbBalances(address);

  return;
};
const onUnstakeNfts = async () => {
  const hash = await frbFarmContractWrite.write.unstakeNfts(
    [],
    {
      from: address,
      chain,
    }
  );
  await publicClient.waitForTransactionReceipt({
    hash:hash.toString()
  });

  getFrbBalances(address);

return;
};

  return { onStakeCanary, onUnstakeCanary, onRebase, onUseFaucet, onNftFaucet, onMintReward, onMintBronze, onBurn2Mint, onVote, onCharge, onChargeReborn, onChadDeposit, onChadWithdraw, onChadEnable, onEnableFarm, onClaimPendingRewards, onXenosEnableFarm, onXenosClaimPendingRewards, onXenos2EnableFarm, onXenos2ClaimPendingRewards, onEnableCenFarm, onClaimCenRewards, onMintGold, onApprove, onLpStake, onLpUnstake, onLpHarvest, onClaimAsAirdrop, onStakeNfts, onUnstakeNfts };
};
