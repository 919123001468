import { createContext, useEffect, useState } from "react";
import { formatEther, formatUnits } from "viem";
import { useAccount, usePublicClient } from "wagmi";
import {
  frbfarm_ABI,
  FRBFARM_CONTRACT_ADDRESS,
  frbairdrop_ABI,
  FRBAIRDROP_CONTRACT_ADDRESS,
  ERC20_ABI,
  ERC20_CONTRACT_ADDRESS,
  BLAZELP_CONTRACT_ADDRESS,
} from "../lib/constants";

// initial state
const defaultValues = {
  balance: 0,
  stakeBalance: 0,
  frbBalance:0,
  bronzeStaked: 0,
  silverStaked: 0,
  goldStaked:0,
  pendingRewards: 0,
  frbStakedBalance: 0,
  wFlrBalance:0,
  userLpBalance:0,
  maxStakingAmount:0,
  pendingSnapshotAmount:0,
  getFrbBalances: () => {},
};
// context
export const FrbBalanceContext = createContext(defaultValues);

export const FrbBalanceProvider = ({ children }) => {
  const publicClient = usePublicClient();
  const { address, isConnected } = useAccount();

  const [state, setBalances] = useState({
    balance: 0,
    stakeBalance: 0,
    frbBalance:0,
    bronzeStaked: 0,
    silverStaked: 0,
    goldStaked:0,
    pendingRewards: 0,
    frbStakedBalance: 0,
    wFlrBalance:0,
    userLpBalance:0,
    maxStakingAmount:0,
    pendingSnapshotAmount:0,
  });

  const getFrbBalances = async (address) => {
      const [balance, stakeBalance, frbBalance, bronzeStaked, silverStaked, goldStaked, pendingRewards,frbStakedBalance,wFlrBalance, userLpBalance, maxStakingAmount, pendingSnapshotAmount] = await Promise.all([
        publicClient.readContract({
          abi: ERC20_ABI,
          address: BLAZELP_CONTRACT_ADDRESS,
          functionName: "balanceOf",
          args: [address],
        }),
        publicClient.readContract({
          abi: frbfarm_ABI,
          address: FRBFARM_CONTRACT_ADDRESS,
          functionName: "getBalance",
          args: [address],
        }),
        publicClient.readContract({
          abi: ERC20_ABI,
          address: ERC20_CONTRACT_ADDRESS,
          functionName: "balanceOf",
          args: [address],
        }),
        publicClient.readContract({
          abi: frbfarm_ABI,
          address: FRBFARM_CONTRACT_ADDRESS,
          functionName: "bronzeStaked",
          args: [address],
        }),
        publicClient.readContract({
          abi: frbfarm_ABI,
          address: FRBFARM_CONTRACT_ADDRESS,
          functionName: "silverStaked",
          args: [address],
        }),
        publicClient.readContract({
          abi: frbfarm_ABI,
          address: FRBFARM_CONTRACT_ADDRESS,
          functionName: "goldStaked",
          args: [address],
        }),
        publicClient.readContract({
          abi: frbfarm_ABI,
          address: FRBFARM_CONTRACT_ADDRESS,
          functionName: "getPendingRewards",
          args: [address],
        }),
        publicClient.readContract({
          abi: frbfarm_ABI,
          address: FRBFARM_CONTRACT_ADDRESS,
          functionName: "userCrbBalance",
          args: [address],
        }),
        publicClient.readContract({
          abi: frbfarm_ABI,
          address: FRBFARM_CONTRACT_ADDRESS,
          functionName: "userWsgbBalance",
          args: [address],
        }),
        publicClient.readContract({
          abi: frbfarm_ABI,
          address: FRBFARM_CONTRACT_ADDRESS,
          functionName: "userLpBalance",
          args: [address],
        }),
        publicClient.readContract({
          abi: frbfarm_ABI,
          address: FRBFARM_CONTRACT_ADDRESS,
          functionName: "getMaxStakingAmount",
          args: [address],
        }),
        publicClient.readContract({
          abi: frbairdrop_ABI,
          address: FRBAIRDROP_CONTRACT_ADDRESS,
          functionName: "getTotalAmount",
          args: [address],
        }),
      ]);
      setBalances({
        balance: formatEther(balance || "0"),
        stakeBalance: formatEther(stakeBalance || "0"),
        frbBalance: formatEther(frbBalance || "0"),
        pendingRewards: formatEther(pendingRewards || "0"),
        bronzeStaked:formatUnits(bronzeStaked || "0"),
        silverStaked:formatUnits(silverStaked || "0"),
        goldStaked:formatUnits(goldStaked || "0"),
        frbStakedBalance: formatEther(frbStakedBalance || "0"),
        wFlrBalance: formatEther(wFlrBalance || "0"),
        userLpBalance: formatUnits(userLpBalance,0),
        maxStakingAmount: formatEther(maxStakingAmount || "0"),
        pendingSnapshotAmount: formatEther(pendingSnapshotAmount || "0"),
      });
    
  };

  useEffect(() => {
    isConnected && getFrbBalances(address);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [address, isConnected]);

  return (
    <FrbBalanceContext.Provider value={{ ...state, getFrbBalances }}>
      {children}
    </FrbBalanceContext.Provider>
  );
};
