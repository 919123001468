import { BankIcon, Query } from "@/src/icons";
import { Card, Button } from "@/src/components";
import { useFrbBalances } from "@/src/hooks/useBalances";
import { useStake } from "@/src/hooks/useStake";
import { useAccount } from "wagmi";
import { toast } from "react-hot-toast";

export const FrbPendingRewards = () => {
  const { pendingRewards } = useFrbBalances();
  const { onClaimAsAirdrop } = useStake();
  const { isConnected } = useAccount();

  const handleAirdrop = async () => {
    if (!isConnected) return;

    toast.promise(onClaimAsAirdrop(), {
      loading: "Transaction is in progress",
      success: "Successfully added FRB to airdrop",
      error: (data) => data,
    });
  }
  return (
    <Card className="md:h-2/7 py-5">
      <div className="grid gap-3">
        <div>
          <p className="text-2xl md:text-3xl font-semibold text-foreground">
          {parseFloat(pendingRewards).toFixed(2)} FRB
          </p>
          <h2 className="text-xl font-semibold text-foreground/50">Pending Rewards</h2>
        </div>
        <div className="flex items-center h-full">
        <Button onClick={handleAirdrop} className="" disabled={parseFloat(pendingRewards)==0}>
          <h2 className="md:text-xl text-xl text-foreground">Claim As Airdrop</h2>
        </Button>
        {}
      </div>
      </div>
    </Card>
    
  );
};
