import { createContext, useEffect, useState } from "react";
import { formatEther, formatUnits } from "viem";
import { useAccount, usePublicClient } from "wagmi";
import {
  CANARY_DAO_ABI,
  DAO_CONTRACT_ADDRESS,
  ERC20_ABI,
  ERC20_CONTRACT_ADDRESS,
  NFT_ABI,
  NFT_CONTRACT_ADDRESS,
  LP_ABI,
  LP_CONTRACT_ADDRESS,
  LP2_CONTRACT_ADDRESS,
  REWARD_ABI,
  REWARD_CONTRACT_ADDRESS,
  CNYX_CONTRACT_ADDRESS,
  VOTE_CONTRACT_ADDRESS,
  VOTE_ABI,
  BRONZE_CONTRACT_ADDRESS,
  BRONZE_ABI,
  BURN2MINT_ABI,
  BURN2MINT_CONTRACT_ADDRESS,
  CRBPRICE_ABI,
  CRBPRICE_ADDRESS,
  CHAD_ABI,
  CHAD_ADDRESS,
  FARM_ABI,
  FARM_ADDRESS,
  XENOSFARM_ABI,
  XENOS_FARM_ADDRESS,
  XENOS2_FARM_ADDRESS,
  CEN_FARM_ADDRESS,
  FLRB2M_ABI,
  FLRB2M_ADDRESS,
} from "../lib/constants";

// initial state
const defaultValues = {
  farmBalance: 0,
  pendingRewards: 0,
  farmStatus: 0,
  xenosfarmBalance: 0,
  xenosPendingRewards: 0,
  xenosFarmStatus: 0,
  bronzeSupply: 0,
  getFarmBalances: () => {},
};
// context
export const FarmContext = createContext(defaultValues);

export const FarmProvider = ({ children }) => {
  const publicClient = usePublicClient();
  const { address, isConnected } = useAccount();

  const [state, setFarmBalances] = useState({
    farmBalance: 0,
    pendingRewards: 0,
    farmStatus: 0,
    xenosfarmBalance: 0,
    xenosPendingRewards: 0,
    xenosFarmStatus: 0,
    bronzeSupply:0,
  });

  const getFarmBalances = async (address) => {
    //try {
      const [xenosfarmBalance, xenosPendingRewards, xenosFarmStatus, bronzeSupply] = await Promise.all([
        publicClient.readContract({
          abi: XENOSFARM_ABI,
          address: XENOS_FARM_ADDRESS,
          functionName: "getUserTotalBalance",
          args: [address],
        }),
        publicClient.readContract({
          abi: XENOSFARM_ABI,
          address: XENOS_FARM_ADDRESS,
          functionName: "getPendingRewards",
          args: [address],
        }),
        publicClient.readContract({
          abi: XENOSFARM_ABI,
          address: XENOS_FARM_ADDRESS,
          functionName: "farming",
          args: [address],
        }),
        publicClient.readContract({
          abi: BRONZE_ABI,
          address: BRONZE_CONTRACT_ADDRESS,
          functionName: "totalSupply",
          args: [],
                }),
      ]);
      setFarmBalances({
        xenosfarmBalance: formatEther(xenosfarmBalance || "0"),
        xenosPendingRewards: formatEther(xenosPendingRewards || "0"),
        xenosFarmStatus: formatUnits(xenosFarmStatus,0),
        bronzeSupply: bronzeSupply || "0",
      });
    //} catch (err) {}
    
  };

  useEffect(() => {
    isConnected && getFarmBalances(address);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [address, isConnected]);

  return (
    <FarmContext.Provider value={{ ...state, getFarmBalances }}>
      {children}
    </FarmContext.Provider>
  );
};
